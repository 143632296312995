/*------------------------------------*\
  #SHAME
\*------------------------------------*/

/**
 * Hacky, nasty code. These bits have to be repaired!
 */

.gallery-container {
  img {
    height: 90vh;
    object-fit: cover;
  }

  @media screen and (max-width: 1023px) {
    img {
      height: 45vh;
      object-fit: cover;
    }
  }
}

.hero-heading {
  height: 50%;
  top: 25%;
}

.text-shadow {
  text-shadow: 0 3px 5px rgba(50, 50, 50, 0.25);
}

.nano {
  font-size: 8px;
}

.scale {
  &:hover {
    transform: scale(150%);
  }
}

.gallery--carousel__item {
  padding: 2.5% 1.5%;

  &:first-child {
    padding-left: 2.5%;
  }
}

.bg--ui-dark {
  background-color: #363636 !important;
}

span.wishlist-li {
  color: white;
}

.underline {
  border-bottom: 1px solid #fff;
}

.site-header  {
  img {
    height: auto!important;
  }

  .l, .l__item, >div, .site-header__brand, .site-header__brand img {
    height: 100%!important;
  }
}

.trip-card {
  height: auto!important;
}

@media screen and (min-width: 720px) {
  .trip-card--overlay {
    height: 0 !important;
  }
}

.trip-card__img {
  min-height: 0;
}


@media screen and (max-width: 719px) {
  .slick-next {
    right: 0;
  }

  .slick-prev {
    left: 0;
  }
}

.word-break {
  word-break: break-all;
}

// Don't allow placeholder to cause scrolling
.search-field {
  max-width: 100%;
  // overflow: hidden;
}
